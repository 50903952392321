import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { Skeleton } from '@material-ui/lab';
import Product from './Product/Product';
import useStyles from './styles';
import Loader from "../UI/Loader";
import Pagination from "../Pagination";
import { getProducts } from '../../api/services/product';
import { getCategories } from '../../api/services/organization';
import { Euro, Fastfood, FilterHdr, KingBed, ShoppingBasket, Star, LocationOn } from '@material-ui/icons';
import { Button } from '@material-ui/core';
import { UseErrorContext } from '../../global/contexts';
import Food from '../../assets/food.jpg';
import Shopping from '../../assets/shopping.jpg';
import Hotel from '../../assets/hotel.jpg';
import Adventure from '../../assets/adventure.jpg';
import Wellness from '../../assets/wellness.jpg';
import Other from '../../assets/other.jpg';
import Page from '../UI/Elements/Page';

const Products = ({ onAddToCart, search }) => {
  const classes = useStyles();
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filterCategory, setFilterCategory] = useState(null);
  const [filterPrice, setFilterPrice] = useState(null);
  const [filterLocation, setFilterLocation] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const { updateError } = UseErrorContext();

  useEffect(() => {
    const refresh = async () => {
      try{        
        setLoading(true);

        let params = {
          'categoryId': filterCategory,
          'price': filterPrice,
          'location': filterLocation
        };
        if (search){
          params['search'] = search;
        }
        const data = await getProducts(params);        
        setProducts(data);
  
        setLoading(false);
      } catch (err) {
        updateError(err);
      }
    };

    refresh();
  }, [currentPage, search, filterCategory, filterPrice, filterLocation]);

  useEffect(() => {
    const initCategories = async () => {
      try{        
        setLoading(true);
        const data = await getCategories();    
        setCategories(data);
        setLoading(false);
      } catch (err) {
        updateError(err);
      }
    };
    initCategories();
  }, []);

  const onChangeFilterCategory = (category) => {
    if (filterCategory !== category.id){
      setFilterCategory(category.id);
    }
    else{
      setFilterCategory(null);
    }
  }

  const onChangeFilterPrice = (price) => {
    if (filterPrice !== price){
      setFilterPrice(price);
    }
    else{
      setFilterPrice(null);
    }
  }
  
  const onChangeFilterLocation = (location) => {
    if (filterLocation !== location){
      setFilterLocation(location);
    }
    else{
      setFilterLocation(null);
    }
  }

  const renderCategoryImage = (name) => {
    let source;

    switch(name) {
      case 'Eten & Drinken':
        source = Food; break;
      case 'Overnachten':
        source = Hotel; break;
      case 'Shopping':
        source = Shopping; break;
      case 'Overige':
        source = Other; break;
      case 'Avontuur':
        source = Adventure; break;
        case 'Wellness':
          source = Wellness; break;
    }

    return (
      <img src={source} alt={name} className={classes.categoryImg} width="auto"/>
    );
  }

  if (loading) return <Loader center />;

  return (
    <Page noBackground={true}>
      <div className={classes.topbar}>
          {categories.map((category, idx) => (   
            <div className={classes.categoryCard + `${filterCategory == category.id ? ' ' + classes.active : ''} `} key={idx} onClick={() => onChangeFilterCategory(category)}>
              <div className={classes.categoryImgBox}>{renderCategoryImage(category.name)}</div>
              <p className={classes.categoryText}>{category.name}</p>   
            </div>  
          ))}   
      </div> 
      { products.length === 0
      ? <p>Geen resultaten gevonden</p>
      : <>         
        <div className={classes.filters}>
          <Button variant="outlined" className={`${filterPrice == "50" ? 'customButton' : 'customButton2'}`} startIcon={<Euro/>} onClick={() => onChangeFilterPrice(50)}>Onder de 50</Button>
          <Button variant="outlined" className={`${filterLocation == "Antwerpen" ? 'customButton' : 'customButton2'} `} startIcon={<LocationOn/>} onClick={() => onChangeFilterLocation("Antwerpen")}>Antwerpen</Button>
        </div>  
        <div className={classes.content}> 
          <div className={classes.products}>         
            <Grid container alignItems="stretch" className={classes.productGrid}>
              {products.map((product, idx) => (
                <Grid key={product.id} item xs={12} sm={12} md={4} lg={3}>
                  { product 
                    ? (<Product product={product} onAddToCart={onAddToCart} idx={idx}/>)
                    : (<Skeleton variant="rect" width={210} height={118} />)
                  }
                </Grid>
              ))}
            </Grid>
            <Pagination numberOfPages={numberOfPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
          </div>
        </div>
      </> 
    }
    </Page>
  );
};

export default Products;

