import React, { useState, useEffect } from 'react';
import Page from "../UI/Elements/Page";
import { printVoucher } from '../../api/services/order';
import { Loader } from 'semantic-ui-react';
import { UseErrorContext } from '../../global/contexts';
import styled from "styled-components";

const Div = styled.div`  
  .footer{
    margin: 1rem 0;
  }
`;

  const Success = () => {
    const [loading, setLoading] = useState(false);
    const [unique, setUnique] = useState("");
    const { updateError } = UseErrorContext();

    useEffect(() => {
      const init = async () => {
        localStorage.setItem('items', JSON.stringify([]))
        localStorage.setItem('messages', JSON.stringify([]))
        localStorage.setItem('customer', JSON.stringify({}))

        // Get unique number
        let u = JSON.parse(localStorage.getItem('unique')) ?? "";
        setUnique(u);

        if (u !== ''){
          await _printVoucher(u);
        }
      };

      init();
  }, []);

  const _printVoucher = async (u) => {
    try{      
      setLoading(true);
      const result = await printVoucher(u);
      const file = new Blob(
        [result.data], 
        {type: 'application/pdf'});
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    } catch (err) {
      updateError(err);
    }
    finally{      
      setLoading(false);
    }
  };

  return (
    <Div>
    <Page noBackground>
      <div className="page__header">
        <h1>Bedankt voor uw aankoop</h1>
      </div>
      <div className="page__inner">
      { loading
        ? <Loader center />
        :
          <div>
            <p>U krijgt zo dadelijk een e-mail met uw orderbevestiging.</p>
          </div>
          }
      </div>
      <div className="footer">
            {unique != "" && 
              <p><a onClick={async() => _printVoucher(unique)} href='#'>Cadeaubon(en) afdrukken</a></p>
            }
        {/* <p><a href="/">Winkel verder</a></p> */}
      </div>
    </Page>
    </Div>
  );
};

export default Success;
