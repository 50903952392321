import React, { useState, useEffect } from 'react';
import { Typography, Button, Divider } from '@material-ui/core';
import useStyles from './styles';
import { getTotalTransactionIncluded, getTransactionPrice } from '../../global/datahelper';
import Review from './Review';
import Loader from "../UI/Loader";
import { UseErrorContext } from '../../global/contexts';
import { addOrder, createPayment } from '../../api/services/order';
import { useHistory } from "react-router-dom";

// test VISA: 4000000560000004

const PaymentForm = ({ nextStep, backStep }) => {
  const history = useHistory();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { updateError } = UseErrorContext();
  const [cartItems, setCartItems] = useState(JSON.parse(localStorage.getItem('items')) ?? []);
  const [invoiceData, setInvoiceData] = useState(JSON.parse(localStorage.getItem('customer')) ?? {});
  const [messageData, setMessageData] = useState(JSON.parse(localStorage.getItem('messages')) ?? []);

  const total = getTotalTransactionIncluded(cartItems);

  const pay = async () => {
    try{
      setLoading(true);

      //Create Order
      const orderData = {
         items: cartItems,
        invoice: invoiceData,
        vouchers: messageData
      };
      var order = await addOrder(orderData);
      let uniqueNumber = order.uniqueNumber ?? "";
      localStorage.setItem('unique', JSON.stringify(uniqueNumber));
      
      // Create Payment
      const payment = await createPayment(order.id);
    
      setLoading(false);
      window.location.href = payment.sessionUrl;
    }
    catch(err){
      updateError(err);
    }
  }

  return (
    <>
      <Review cartItems={cartItems} />
      <Divider />
      <form>
        <br/><p className="small-text"><i>Transactiekosten (€{getTransactionPrice(cartItems)})</i></p><br/>
        { loading 
              ? <Loader center />
              : <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Button variant="outlined" onClick={backStep}>Terug</Button>
                  { total > 0.50
                  ? <Button onClick={() => pay()} className="customButton paymentButton">
                      {/* Betaal €{total} */}
                      Betaal
                    </Button>
                  : <p>Bedrag moet groter zijn dan €0,50</p>
                  }
                </div>
          }
      </form>
    </>
  );
};

export default PaymentForm;
